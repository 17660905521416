var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
let nanoid = (size = 21) => {
  let id = "";
  let bytes = crypto.getRandomValues(new Uint8Array(size));
  while (size--) {
    let byte = bytes[size] & 63;
    if (byte < 36) {
      id += byte.toString(36);
    } else if (byte < 62) {
      id += (byte - 26).toString(36).toUpperCase();
    } else if (byte < 63) {
      id += "_";
    } else {
      id += "-";
    }
  }
  return id;
};
class AsyncQuery {
  constructor({
    metaKey,
    parameters,
    query,
    cancel,
    enabled,
    queryId
  }) {
    __publicField(this, "enabled");
    __publicField(this, "parameters");
    __publicField(this, "metaKey");
    __publicField(this, "queryId");
    __publicField(this, "_canceled");
    __publicField(this, "_data");
    __publicField(this, "_error");
    __publicField(this, "_status");
    __publicField(this, "cancelFn");
    __publicField(this, "queryFn");
    this._status = "idle";
    this.queryFn = query != null ? query : () => Promise.resolve();
    this.cancelFn = cancel;
    this.parameters = parameters;
    this._canceled = false;
    this.metaKey = metaKey != null ? metaKey : nanoid();
    this.queryId = queryId != null ? queryId : nanoid();
    this.enabled = enabled != null ? enabled : true;
  }
  get status() {
    return this._status;
  }
  get data() {
    return this._data;
  }
  get error() {
    return this._error;
  }
  get canceled() {
    return this._canceled;
  }
  toString() {
    return `Query(${this.parameters}) { status: ${this.status}, canceled: ${this.canceled}, enabled: ${this.enabled} }`;
  }
  async run() {
    if (this.enabled === false) {
      return;
    }
    this._status = "loading";
    this._canceled = false;
    return this.queryFn().then((res) => {
      if (this._canceled) {
        throw new Error("Canceled");
      }
      this._data = res;
      this._status = "success";
    }).catch((err) => {
      this._status = "error";
      this._error = err;
    });
  }
  cancel() {
    var _a;
    if (this._status === "loading") {
      this._status = "error";
      this._canceled = true;
      this._error = new Error("Canceled");
      (_a = this.cancelFn) == null ? void 0 : _a.call(this);
    }
  }
  viewUpdate(editor, {
    ignoreCanceled = false,
    ignoreError = false,
    ignoreLoading = false,
    ignoreSuccess = false
  } = {}) {
    if (this.enabled === false) {
      return;
    }
    if (this.status === "idle") {
      this.run().finally(() => {
        if (this._canceled && ignoreCanceled) {
          return;
        }
        if (this.status === "success" && ignoreSuccess) {
          return;
        }
        if (this.status === "error" && ignoreError) {
          return;
        }
        const transaction2 = editor.state.tr;
        transaction2.setMeta("addToHistory", false);
        transaction2.setMeta(this.metaKey, {
          queryId: this.queryId
        });
        editor.dispatch(transaction2);
      });
      if (ignoreLoading) {
        return;
      }
      const transaction = editor.state.tr;
      transaction.setMeta("addToHistory", false);
      transaction.setMeta(this.metaKey, {
        queryId: this.queryId
      });
      editor.dispatch(transaction);
    }
  }
  viewDestroy() {
    this.cancel();
  }
  statusChanged(tr, status) {
    var _a;
    let reportChange = ((_a = tr.getMeta(this.metaKey)) == null ? void 0 : _a.queryId) === this.queryId;
    if (typeof status === "string") {
      reportChange = this.status === status && reportChange;
    } else if (Array.isArray(status)) {
      reportChange = status.indexOf(this.status) !== -1 && reportChange;
    }
    return reportChange;
  }
  static empty() {
    return new AsyncQuery({
      enabled: false
    });
  }
}
export { AsyncQuery };
